import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider } from 'react-router-dom';
import { KeycloakProvider } from './KeycloakContext.tsx';
import { router } from './Routes/Routes.tsx';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  // <React.StrictMode>
    <KeycloakProvider>
      <RouterProvider router={router} />
    </KeycloakProvider>
  // </React.StrictMode>
);
