import Keycloak from 'keycloak-js';

declare global {
  interface Window {
    _env_?: {
      REACT_APP_KEYCLOAK_URL: string;
      REACT_APP_KEYCLOAK_REALM: string;
      REACT_APP_KEYCLOAK_CLIENT_ID: string;
    };
  }
}

const fallbackEnv = {
  REACT_APP_KEYCLOAK_URL: process.env.REACT_APP_KEYCLOAK_URL,
  REACT_APP_KEYCLOAK_REALM: process.env.REACT_APP_KEYCLOAK_REALM,
  REACT_APP_KEYCLOAK_CLIENT_ID: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
};

const getRuntimeEnv = () => window._env_ || fallbackEnv;

let keycloak: Keycloak | null = null;
let alreadyInitialized = false;

export const getKeycloak = () => {
  if (!keycloak) {
    const env = getRuntimeEnv();
    keycloak = new Keycloak({
      url: env.REACT_APP_KEYCLOAK_URL!,
      realm: env.REACT_APP_KEYCLOAK_REALM!,
      clientId: env.REACT_APP_KEYCLOAK_CLIENT_ID!,
    });
  }
  return keycloak;
};

export const initKeycloak = async () => {
  if (alreadyInitialized) return;
  const kc = getKeycloak();
  await kc.init({
    onLoad: 'check-sso',
    pkceMethod: 'S256',
    checkLoginIframe: false,
  });
  alreadyInitialized = true;
};
