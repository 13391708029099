// src/Routes/Routes.tsx
import { createBrowserRouter } from 'react-router-dom';
import App from '../App.tsx';
import HomePage from '../Pages/HomePage/HomePage.tsx';
import VideoToMp3Page from '../Pages/VideoToMp3Page/VideoToMp3Page.tsx';
import PrivateRoute from './PrivateRoute.tsx'; // import the route guard

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { path: '', element: <HomePage /> },
      {
        path: 'video-to-mp3',
        element: (
          <PrivateRoute>
            <VideoToMp3Page />
          </PrivateRoute>
        ),
      },
    ],
  },
]);
