import 'dropzone/dist/dropzone.css';
import React from 'react';
import { useDropzone } from 'react-dropzone';

interface VideoUploadDropzoneProps {
  onDrop: (acceptedFiles: File[]) => void;
}

const VideoUploadDropzone: React.FC<VideoUploadDropzoneProps> = ({ onDrop }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'video/*': ['.mp4', '.mov', '.avi', '.mkv', '.webm'],
    },
    multiple: false,
  });

  return (
    <div
      {...getRootProps({ className: 'dropzone' })}
      style={{
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
      }}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the video here ...</p>
      ) : (
        <p>Drag 'n' drop a video file here, or click to select one</p>
      )}
    </div>
  );
};

export default VideoUploadDropzone;
