import React, { useState } from 'react';
import VideoUploadDropzone from '../../Components/VideoUploadDropzone/VideoUploadDropzone.tsx';

const VideoToMp3Page: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setSelectedFile(acceptedFiles[0]);
    }
  };

  const handleConvert = async () => {
    if (!selectedFile) return;

    // TODO: Actually convert MP4 → MP3 here.
    // 1) Option A: Upload 'selectedFile' to a backend endpoint that does ffmpeg conversion server-side.
    // 2) Option B: Use an in-browser library like ffmpeg.wasm.

    alert(`Converting ${selectedFile.name} to MP3 (placeholder)`);
  };

  return (
    <div style={{ maxWidth: '500px', margin: '0 auto' }}>
      <h1>Video to MP3 Converter</h1>
      <VideoUploadDropzone onDrop={handleDrop} />
      {selectedFile && (
        <div style={{ marginTop: '1rem' }}>
          <strong>Selected file:</strong> {selectedFile.name}
        </div>
      )}
      <button
        onClick={handleConvert}
        disabled={!selectedFile}
        style={{
          marginTop: '1rem',
          padding: '0.5rem 1rem',
          cursor: selectedFile ? 'pointer' : 'not-allowed',
        }}
      >
        Convert to MP3
      </button>
    </div>
  );
};

export default VideoToMp3Page;
