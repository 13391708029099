// KeycloakContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getKeycloak, initKeycloak } from './keycloak.ts';

interface KeycloakContextType {
  keycloak: ReturnType<typeof getKeycloak>;
  initialized: boolean;
  authenticated: boolean;
  username?: string;
}

const KeycloakContext = createContext<KeycloakContextType>({
  keycloak: getKeycloak(),
  initialized: false,
  authenticated: false,
});

export const useKeycloakContext = () => useContext(KeycloakContext);

export const KeycloakProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [initialized, setInitialized] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [username, setUsername] = useState<string>();
  const keycloak = getKeycloak();

  useEffect(() => {
    // The 'initKeycloak()' call has an internal guard
    initKeycloak().then(() => {
      setAuthenticated(!!keycloak.authenticated);
      setUsername(keycloak.tokenParsed?.preferred_username);
      setInitialized(true);
    });
  }, [keycloak]);

  if (!initialized) return <div>Loading Keycloak...</div>;

  return (
    <KeycloakContext.Provider value={{ keycloak, initialized, authenticated, username }}>
      {children}
    </KeycloakContext.Provider>
  );
};
