// src/Routes/PrivateRoute.tsx
import React from 'react';
import { useKeycloakContext } from '../KeycloakContext.tsx'; // adjust path as needed

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { keycloak, authenticated } = useKeycloakContext();

  // If the user is not authenticated, either:
  // 1) Redirect them to a login page or
  // 2) Call keycloak.login() directly.

  if (!authenticated) {
    // Option A: Immediately call keycloak.login() to force login
    keycloak.login();
    return null; // no UI while we wait

    // Option B: Use a redirect
    // return <Navigate to="/" />;
  }

  // If authenticated, render the protected page
  return <>{children}</>;
};

export default PrivateRoute;
