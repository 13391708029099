// Navbar.tsx
import { useKeycloakContext } from '../../KeycloakContext.tsx';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const { keycloak, authenticated, username } = useKeycloakContext();

  return (
    <nav className="bg-white shadow-md px-6 py-4 flex justify-between items-center">
      {/* Left side */}
      <div className="flex items-center space-x-4">
        <div>Welcome</div>

        {/* Show "Convert Video to MP3" only if user is authenticated */}
        {authenticated && (
          <Link
            to="/video-to-mp3"
            className="bg-gray-200 text-gray-800 px-4 py-2 rounded hover:bg-gray-300"
          >
            Convert Video to MP3
          </Link>
        )}
      </div>

      {/* Right side */}
      <div>
        {authenticated ? (
          <>
            <span>Hello, {username}</span>{' '}
            <button
              onClick={() =>
                keycloak.logout({
                  redirectUri: window.location.origin + '/',
                })
              }
              className="ml-4 bg-gray-200 text-gray-800 px-4 py-2 rounded hover:bg-gray-300"
            >
              Logout
            </button>
          </>
        ) : (
          <button
            onClick={() => keycloak.login()}
            className="bg-gray-200 text-gray-800 px-4 py-2 rounded hover:bg-gray-300"
          >
            Login
          </button>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
